import * as Sentry from "@sentry/browser"
//import SentryRRWeb from '@sentry/rrweb'
import browserlist from "./libs/supported_browsers"

window.browserlist = browserlist

Sentry.init({
  dsn: "https://8ca41478fe304c4ba00edca943b7a585@sentry.xan.pl/2",
  environment: "production",
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Illegal invocation',
    'TagError: adsbygoogle.push() error: No slot size for availableWidth=0',
    'TypeError: Cannot set properties of undefined (setting \'history\')', // bounds.js
    'SecurityError: The operation is insecure.',
    'SecurityError: Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.'
  ],
  denyUrls: [
    // cpm from quantcast
    /cmp\.quantcast\.com/i,
    // Google Adsense
    /pagead\/js/i,
    /pagead2\.googlesyndication\.com/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  integrations: [
    //   new SentryRRWeb({}),
  ],
  // don't send errors from old browsers
  beforeSend(event) {
    if (!browserlist.test(navigator.userAgent)) return null
    if (location.host !== "fdb.pl") return null

    return event;
  }
})
